import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Main from './Components/Main/Main';
import './App.css';

// Lazy-loaded components
const AboutPage = React.lazy(() => import('./Components/Pages/About-page/AboutPage'));
const Gallery = React.lazy(() => import('./Components/Pages/Gallery-page/Gallery'));
const ContactPage = React.lazy(() => import('./Components/Pages/ContactPage'));
const ServicePage = React.lazy(() => import('./Components/Pages/Service-page/ServicePage'));
const TermAndCondition = React.lazy(() => import('./Components/Pages/Terms-page/TermAndCondition'));
const ValplastFlexible = React.lazy(() => import('./Components/Pages/Service-page/Single-service-pages/ValplastFlexible'));
const CosmeticDentistry = React.lazy(() => import('./Components/Pages/Service-page/Single-service-pages/CosmeticDentistry'));
const ScrewRetainCrown = React.lazy(() => import('./Components/Pages/Service-page/Single-service-pages/ScrewRetainCrown'));
const CPD = React.lazy(() => import('./Components/Pages/Service-page/Single-service-pages/CPD'));
const NightGaurd = React.lazy(() => import('./Components/Pages/Service-page/Single-service-pages/NightGaurd'));
const InlayOnlay = React.lazy(() => import('./Components/Pages/Service-page/Single-service-pages/InlayOnlay'));
const Veneer = React.lazy(() => import('./Components/Pages/Service-page/Single-service-pages/Veneer'));
const SnapOnSmile = React.lazy(() => import('./Components/Pages/Service-page/Single-service-pages/SnapOnSmile'));
const CustomTray = React.lazy(() => import('./Components/Pages/Service-page/Single-service-pages/CustomTray'));
const ModelCreate = React.lazy(() => import('./Components/Pages/Service-page/Single-service-pages/ModelCreate'));
const SurgicalGuide = React.lazy(() => import('./Components/Pages/Service-page/Single-service-pages/SurgicalGuide'));
const DigitalDenture = React.lazy(() => import('./Components/Pages/Service-page/Single-service-pages/DigitalDenture'));
const Crown = React.lazy(() => import('./Components/Pages/Service-page/Single-service-pages/Crown'));
const BlogPage = React.lazy(() => import('./Components/Pages/Blog-page/BlogPage'));
const FirstBlogPage = React.lazy(() => import('./Components/Pages/Blog-page/Single-Blog-Page/FirstBlogPage'));
const SecondBlogPage = React.lazy(() => import('./Components/Pages/Blog-page/Single-Blog-Page/SecondBlogPage'));
const ThirdBlogPage = React.lazy(() => import('./Components/Pages/Blog-page/Single-Blog-Page/ThirdBlogPage'));
const FourthBlogPage = React.lazy(() => import('./Components/Pages/Blog-page/Single-Blog-Page/FourthBlogPage'));
const FifthBlogPage = React.lazy(() => import('./Components/Pages/Blog-page/Single-Blog-Page/FifthBlogPage'));
const SixthBlogPage = React.lazy(() => import('./Components/Pages/Blog-page/Single-Blog-Page/SixthBlogPage'));

const App = () => {

  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>

          {/* Default Route */}
          <Route
            path="/*"
            element={<Main />}
          />

          {/* Routes for lazy-loaded components */}
          <Route
            exact path="/about-page"
            element={<AboutPage />}
          />

          <Route
            exact path="/gallery-page"
            element={<Gallery />}
          />

          <Route
            exact path="/services-page"
            element={<ServicePage />}
          />

          <Route
            exact path="/contact-page"
            element={<ContactPage />}
          />

          <Route
            exact path="/term&condition-page"
            element={<TermAndCondition />}
          />

          <Route
            exact path="/service-detail/valplast-flexible"
            element={<ValplastFlexible />}
          />

          <Route
            exact path="/service-detail/cosmetic-dentistry"
            element={<CosmeticDentistry />}
          />

          <Route
            exact path="/service-detail/screw-retain-crown"
            element={<ScrewRetainCrown />}
          />

          <Route
            exact path="/service-detail/cpd-rpd"
            element={<CPD />}
          />

          <Route
            exact path="/service-detail/night-guard"
            element={<NightGaurd />}
          />

          <Route
            exact path="/service-detail/inlay-onlay"
            element={<InlayOnlay />}
          />

          <Route
            exact path="/service-detail/veneer"
            element={<Veneer />}
          />

          <Route
            exact path="/service-detail/snap-on-smile"
            element={<SnapOnSmile />}
          />

          <Route
            exact path="/service-detail/custom-tray"
            element={<CustomTray />}
          />

          <Route
            exact path="/service-detail/model-create"
            element={<ModelCreate />}
          />

          <Route
            exact path="/service-detail/surgical-guide"
            element={<SurgicalGuide />}
          />

          <Route
            exact path="/service-detail/digital-denture"
            element={<DigitalDenture />}
          />

          <Route
            exact path="/service-detail/crown-&-bridge"
            element={<Crown />}
          />

          <Route
            exact path="/blogs"
            element={<BlogPage />}
          />

          <Route
            exact path="/blog-post/1"
            element={<FirstBlogPage />}
          />

          <Route
            exact path="/blog-post/2"
            element={<SecondBlogPage />}
          />

          <Route
            exact path="/blog-post/3"
            element={<ThirdBlogPage />}
          />

          <Route
            exact path="/blog-post/4"
            element={<FourthBlogPage />}
          />

          <Route
            exact path="/blog-post/5"
            element={<FifthBlogPage />}
          />

          <Route
            exact path="/blog-post/6"
            element={<SixthBlogPage />}
          />

        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
