import React from 'react';
import loaderGif from '../../Assets/images/loader.gif'; 
import './Loader.css';

const Loader = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <img src={loaderGif} alt="Loading..." />
    </div>
  );
}

export default Loader;
