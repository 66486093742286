import React, { useState, useEffect, Suspense } from 'react';
import Loader from '../Main/loader';

const Banner = React.lazy(() => import('./Home-Page/Banner'));
const Enquiry = React.lazy(() => import('./Home-Page/Enquiry'));
const About = React.lazy(() => import('./Home-Page/About'));
const FunFacts = React.lazy(() => import('./Home-Page/FunFacts'));
const Services = React.lazy(() => import('./Home-Page/Services'));
const WhyChooseUs = React.lazy(() => import('./Home-Page/WhyChooseUs'));
const Doctor = React.lazy(() => import('./Home-Page/Doctor'));
const CTA = React.lazy(() => import('./Home-Page/CTA'));
const Partner = React.lazy(() => import('./Home-Page/Partner'));
const Blog = React.lazy(() => import('./Home-Page/Blog'));
const Footer = React.lazy(() => import('../Footer/Footer'));

const Main = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleLoadComplete = () => {
      setLoading(false);
    };

    const loadComponents = async () => {
      await Promise.all([
        import('./Home-Page/Banner'),
        import('./Home-Page/Enquiry'),
        import('./Home-Page/About'),
        import('./Home-Page/FunFacts'),
        import('./Home-Page/Services'),
        import('./Home-Page/WhyChooseUs'),
        import('./Home-Page/Doctor'),
        import('./Home-Page/CTA'),
        import('./Home-Page/Partner'),
        import('./Home-Page/Blog'),
        import('../Footer/Footer'),
      ]);
      handleLoadComplete();
    };

    loadComponents();

    return () => {
     
    };
  }, []);

  return (
    <>
      {loading && <Loader />}
      <Suspense fallback={null}>
        <div>
          <Banner />
          <Enquiry />
          <About />
          <FunFacts />
          <Services />
          <WhyChooseUs />
          <Doctor />
          <CTA />
          <Partner />
          <Blog />
          <Footer />
        </div>
      </Suspense>
    </>
  );
};

export default Main;
